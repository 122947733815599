@tailwind base;
@tailwind components;
@tailwind utilities; 

body {
  overflow: overlay;;
}

/* width */
::-webkit-scrollbar {
  @apply w-2;
}


/* Track */
::-webkit-scrollbar-track {
  @apply hidden;
}
::-webkit-scrollbar-track-hover {
  @apply hidden;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-300 dark:bg-gray-700 rounded-full;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-400 dark:bg-gray-800;
}

.standard-text {
  @apply text-gray-950 dark:text-gray-200;
}
.standard-text-lc {
  @apply text-gray-700 dark:text-gray-400;
}

.preformatted-text {
  white-space: pre-wrap;
}

